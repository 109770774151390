
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotFound',
  props: {
    resource: {
      type: String,
      default: 'page',
      required: true,
    },
  },
})
