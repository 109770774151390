
import { IGStore } from '@/types'
import { defineComponent, inject } from 'vue'

export default defineComponent({
  name: 'GlobalNavigation',
  setup() {
    return {
      GStore: inject('GStore') as IGStore,
    }
  },
})
