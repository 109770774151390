import { IEvent } from '@/types'
import axios from 'axios'

const baseURL = 'https://my-json-server.typicode.com/Code-Pop/Touring-Vue-Router'
const apiClient = axios.create({ baseURL })

export const getEvents = async (limit = 2, page = 1): Promise<{ events: IEvent[], totalEvents: number }> => {
  const apiResponse = await apiClient.get<IEvent[]>(`/events?_limit=${limit}&_page=${page}`)
  return {
    events: apiResponse.data,
    totalEvents: Number(apiResponse.headers['x-total-count'])
  }
}

export const getEvent = async (eventId: string): Promise<IEvent | undefined> => {
  try {
    const { data: apiResponse } = await apiClient.get<IEvent>(`/events/${eventId}`)
    return apiResponse
  } catch (error) {
    return undefined
  }
}
