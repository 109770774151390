import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d982156c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "flashMessage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.GStore.flashMessage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.GStore.flashMessage), 1))
    : _createCommentVNode("", true)
}