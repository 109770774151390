import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EventNavigation = _resolveComponent("EventNavigation")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.event)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.event.title), 1),
        _createVNode(_component_EventNavigation, {
          "event-id": _ctx.event.id
        }, null, 8, ["event-id"]),
        _createVNode(_component_router_view, { event: _ctx.event }, null, 8, ["event"])
      ]))
    : _createCommentVNode("", true)
}