
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EventNavigation',
  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },
})
