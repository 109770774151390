
import { defineComponent } from 'vue'
import GlobalNavigation from './components/GlobalNavigation.vue'
import FlashMessage from './components/FlashMessage.vue'

export default defineComponent({
  components: {
    GlobalNavigation,
    FlashMessage,
  },
})
