
import { defineComponent, watchEffect } from 'vue'
import EventCard from '@/components/EventCard.vue'
import { getEvents } from '@/services/EventService'
import { IEvent } from '@/types'

export default defineComponent({
  name: 'EventList',
  components: {
    EventCard,
  },
  props: ['page'],
  data() {
    const events: IEvent[] = []
    return {
      events: events,
      totalEvents: 0,
    }
  },
  async created() {
    watchEffect(async () => {
      this.events = []
      const response = await getEvents(2, this.page)

      this.events = response.events
      this.totalEvents = response.totalEvents
    })
  },
  computed: {
    hasNextpage(): boolean {
      const totalPages = Math.ceil(this.totalEvents / 2)
      return this.page < totalPages
    },
  },
})
