
import { defineComponent } from 'vue'
import { IEvent } from '@/types'

export default defineComponent({
  name: 'EventCard',
  props: {
    event: {
      type: Object as () => IEvent,
      required: true,
    },
  },
})
