
import { defineComponent, watchEffect } from 'vue'
import { IEvent } from '@/types'
import { getEvent } from '@/services/EventService'
import EventNavigation from '@/components/EventNavigation.vue'

export default defineComponent({
  name: 'EventLayout',
  props: ['eventId'],
  data() {
    let event: IEvent | undefined
    return {
      event: event,
    }
  },
  async created() {
    watchEffect(async () => {
      const event = await getEvent(this.eventId)
      if (event !== undefined) {
        this.event = event
        return
      }

      this.$router.push({
        name: '404Resource',
        params: { resource: 'event' },
      })
    })
  },
  components: {
    EventNavigation,
  },
})
