
import { defineComponent, inject } from 'vue'
import { IEvent, IGStore } from '@/types'

export default defineComponent({
  name: 'EventRegister',
  setup() {
    return {
      GStore: inject('GStore') as IGStore,
    }
  },
  props: {
    event: {
      type: Object as () => IEvent,
      required: true,
    },
  },
  methods: {
    register() {
      this.GStore.flashMessage = `You are successfully registered for ${this.event.title}`

      setTimeout(() => {
        this.GStore.flashMessage = ''
      }, 1500)

      this.$router.push({
        name: 'EventDetails',
        params: { eventId: this.event.id },
      })
    },
  },
})
