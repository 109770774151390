import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2727a2e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "event-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "event-link",
    to: { name: 'EventDetails', params: { eventId: _ctx.event.id } }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, "@ " + _toDisplayString(_ctx.event.time) + " on " + _toDisplayString(_ctx.event.date), 1),
        _createElementVNode("h4", null, _toDisplayString(_ctx.event.title), 1)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}